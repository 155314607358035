import React, { useState } from "react";
import styled from "styled-components";
// Import images directly
import sample1 from "../assets/sample1.png";
import sample2 from "../assets/sample2.png";
import sample3 from "../assets/sample3.png";
import sample4 from "../assets/sample4.png";
const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    { id: 1, src: sample1, alt: "Pet Portrait 1" },
    { id: 2, src: sample2, alt: "Pet Portrait 2" },
    { id: 3, src: sample3, alt: "Pet Portrait 3" },
    { id: 4, src: sample4, alt: "Pet Portrait 4" },
  ];


   const openLightbox = (image) => {
    setSelectedImage(image);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <Container>
      <h1>Our Gallery</h1>
      <p>Take a look at some of the beautiful artwork we've created!</p>
      <Grid>
        {images.map((image) => (
          <ImageContainer key={image.id}>
            <Image
              src={image.src}
              alt={image.alt}
              onClick={() => openLightbox(image)}
            />
          </ImageContainer>
        ))}
      </Grid>

      {selectedImage && (
        <Lightbox onClick={closeLightbox}>
          <LightboxImage src={selectedImage.src} alt={selectedImage.alt} />
        </Lightbox>
      )}
    </Container>
  );
};


export default Gallery;

/* Styled Components */
const Container = styled.div`
  text-align: center;
  padding: 2em;
  font-family: Arial, sans-serif;

  h1 {
    color: #333;
  }

  p {
    color: #555;
    margin: 1em 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1em;
  margin-top: 2em;
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &:hover img {
    transform: scale(1.05);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
`;

const Lightbox = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const LightboxImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
`;
