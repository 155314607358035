import React, { useState } from "react";
import styled from "styled-components";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    // Simulate form submission
    setTimeout(() => {
      setIsSubmitting(false);
      setMessage("Thank you for reaching out! We will get back to you soon.");
      setFormData({ name: "", email: "", message: "" });
    }, 2000);
  };

  return (
    <Container>
      <h1>Contact Us</h1>
      <p>Have questions or need support? Fill out the form below and we’ll get in touch!</p>

      <Form onSubmit={handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <Textarea
          name="message"
          placeholder="Your Message"
          value={formData.message}
          onChange={handleChange}
          required
        />
        <SubmitButton type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Send Message"}
        </SubmitButton>
      </Form>

      {message && <FeedbackMessage>{message}</FeedbackMessage>}

      <ContactDetails>
        <p>Or reach out to us directly:</p>
        <p>Email: <a href="mailto:support@petartify.com">support@petartify.com</a></p>
        <p>Follow us on <a href="https://instagram.com/petartify" target="_blank" rel="noopener noreferrer">Instagram</a></p>
      </ContactDetails>
    </Container>
  );
};

export default Contact;

/* Styled Components */
const Container = styled.div`
  text-align: center;
  padding: 2em;
  font-family: Arial, sans-serif;

  h1 {
    color: #333;
  }

  p {
    color: #555;
    margin: 1em 0;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em 0;
`;

const Input = styled.input`
  width: 100%;
  max-width: 400px;
  margin: 1em 0;
  padding: 1em;
  border: 2px solid #77c0d9;
  border-radius: 5px;
  font-size: 1em;

  &:focus {
    outline: none;
    border-color: #5aa9bc;
  }
`;

const Textarea = styled.textarea`
  width: 100%;
  max-width: 400px;
  margin: 1em 0;
  padding: 1em;
  border: 2px solid #77c0d9;
  border-radius: 5px;
  font-size: 1em;
  height: 150px;

  &:focus {
    outline: none;
    border-color: #5aa9bc;
  }
`;

const SubmitButton = styled.button`
  padding: 1em 2em;
  background-color: #77c0d9;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  cursor: pointer;

  &:hover {
    background-color: #5aa9bc;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const FeedbackMessage = styled.div`
  margin-top: 2em;
  color: green;
  font-size: 1.2em;
`;

const ContactDetails = styled.div`
  margin-top: 2em;
  color: #555;

  a {
    color: #77c0d9;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

