import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  text-align: center;
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
`;

const Subtitle = styled.p`
  text-align: center;
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const OrderForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Section = styled.section`
  margin: 2rem 0;
`;

const SectionTitle = styled.h2`
  color: #2c3e50;
  margin-bottom: 1rem;
  font-size: 1.5rem;
`;

const FormGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Label = styled.label`
  color: #2c3e50;
  font-weight: 500;
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  resize: vertical;
  min-height: 100px;

  &:focus {
    outline: none;
    border-color: #3498db;
  }
`;

const StyleGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1.5rem;
  margin: 1rem 0;
`;

const StyleCard = styled.div`
  border: 2px solid ${props => props.isSelected ? '#3498db' : '#ddd'};
  border-radius: 10px;
  padding: 1.5rem;
  cursor: pointer;
  background: ${props => props.isSelected ? '#f8f9fa' : 'white'};
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  }
`;

const StyleName = styled.h3`
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
  font-size: 1.2rem;
`;

const StyleDesc = styled.p`
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;

const StyleButton = styled.div`
  margin-top: 1rem;
  padding: 0.5rem;
  background: ${props => props.isSelected ? '#3498db' : '#f0f0f0'};
  color: ${props => props.isSelected ? 'white' : '#333'};
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  transition: all 0.3s ease;
`;

const StylePrice = styled.div`
  margin-top: 0.5rem;
  color: #2c3e50;
  font-weight: bold;
  text-align: center;
`;

const OrderButton = styled.button`
  display: block;
  width: 100%;
  max-width: 300px;
  margin: 2rem auto;
  padding: 1rem 2rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background-color: #2980b9;
    transform: translateY(-2px);
  }

  &:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }
`;

const MessageBox = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 8px;
  text-align: center;
  background-color: ${props => props.error ? '#fee2e2' : '#dcfce7'};
  color: ${props => props.error ? '#dc2626' : '#15803d'};
`;



const UploadInfo = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1rem;

  li {
    color: #666;
    margin: 0.5rem 0;
    &:before {
      content: "•";
      color: #3498db;
      font-weight: bold;
      margin-right: 8px;
    }
  }
`;

const FileInput = styled.input`
  display: block;
  width: 100%;
  padding: 1rem;
  border: 2px dashed #ddd;
  border-radius: 8px;
  margin: 1rem 0;

  &:hover {
    border-color: #3498db;
  }
`;

const FileName = styled.p`
  color: #666;
  margin-top: 0.5rem;
`;

const OrderInfo = styled.div`
  margin-top: 3rem;
  padding: 2rem;
  background: #f8f9fa;
  border-radius: 8px;

  h3 {
    color: #2c3e50;
    margin-bottom: 1rem;
  }

  ol {
    padding-left: 1.5rem;

    li {
      color: #666;
      margin: 0.5rem 0;
    }
  }
`;


// Component Definition
const Order = () => {
  const [style, setStyle] = useState("");
  const [file, setFile] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    petName: "",
    specialRequests: ""
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const styleOptions = [
    {
      id: "royal",
      name: "Royal Pet Portrait",
      description: "Turn your pet into royalty with a crown and luxurious robes",
      prompt: "A regal portrait of the uploaded pet, wearing a royal crown and luxurious robes, sitting on a grand throne, golden accents, ornate background, baroque style, highly detailed",
      buttonText: "Turn My Pet into Royalty!",
      price: "$49.99"
    },
    {
      id: "warrior",
      name: "Fantasy Warrior Pet",
      description: "Transform your pet into an epic fantasy warrior",
      prompt: "The uploaded pet transformed into a fantasy warrior, wearing intricate armor with glowing runes, holding a magical weapon, standing in an epic landscape with mountains and magical elements, cinematic and highly detailed",
      buttonText: "Make My Pet a Warrior!",
      price: "$49.99"
    },
    {
      id: "astronaut",
      name: "Astronaut Pet",
      description: "Send your pet on a space adventure",
      prompt: "The uploaded pet in a sleek astronaut suit, floating in space, surrounded by stars, planets, and glowing nebulae, cyberpunk sci-fi style, vibrant colors",
      buttonText: "Launch My Pet into Space!",
      price: "$49.99"
    },
    {
      id: "cartoon",
      name: "Cartoon Pet Character",
      description: "Create a cute cartoon version of your pet",
      prompt: "A cute cartoon-style version of the uploaded pet, with big expressive eyes, vibrant colors, playful and fun, set in a whimsical background like a park or candyland, animated movie-style illustration",
      buttonText: "Cartoonify My Pet!",
      price: "$49.99"
    },
    {
      id: "superhero",
      name: "Superhero Pet",
      description: "Transform your pet into a mighty superhero",
      prompt: "The uploaded pet as a superhero, wearing a custom costume with a cape, glowing powers, flying through a cityscape with dramatic lighting, comic book style",
      buttonText: "Make My Pet a Superhero!",
      price: "$49.99"
    },
    {
      id: "victorian",
      name: "Victorian-Era Pet Portrait",
      description: "Give your pet an elegant Victorian makeover",
      prompt: "A classic Victorian-style portrait of the uploaded pet, dressed in elegant 19th-century clothing, set in an opulent parlor with vintage furniture and intricate patterns, oil painting aesthetic",
      buttonText: "Victorian Makeover for My Pet!",
      price: "$49.99"
    },
    {
      id: "beach",
      name: "Beach Vacation Pet",
      description: "Send your pet on a tropical vacation",
      prompt: "A fun summer portrait of the uploaded pet, wearing sunglasses and a Hawaiian shirt, holding a tropical drink, relaxing on a sunny beach with waves and palm trees in the background, bright and cheerful",
      buttonText: "Vacationify My Pet!",
      price: "$49.99"
    },
    {
      id: "rockstar",
      name: "Rockstar Pet",
      description: "Make your pet a legendary rockstar",
      prompt: "A bold and vibrant portrait of the uploaded pet, dressed as a rockstar with a leather jacket, sunglasses, and an electric guitar, performing on stage with dramatic lighting",
      buttonText: "Turn My Pet into a Rockstar!",
      price: "$49.99"
    },
    {
      id: "wizard",
      name: "Wizard Pet",
      description: "Transform your pet into a mystical wizard",
      prompt: "The uploaded pet transformed into a mystical wizard, wearing a magical robe and hat, holding a glowing staff, surrounded by enchanted forest elements and mystical effects",
      buttonText: "Make My Pet a Wizard!",
      price: "$49.99"
    },
    {
      id: "pirate",
      name: "Pirate Pet",
      description: "Turn your pet into a swashbuckling pirate",
      prompt: "The uploaded pet dressed as a pirate, wearing a tricorn hat and eye patch, standing on a treasure-filled ship with ocean waves and a dramatic sunset in the background",
      buttonText: "Make My Pet a Pirate!",
      price: "$49.99"
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !style) {
      setMessage("Please upload a photo and select a style.");
      return;
    }

    setLoading(true);
    setMessage("");

    // Create a formatted email template
    const selectedStyle = styleOptions.find(opt => opt.id === style);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('style', JSON.stringify(selectedStyle));
    formData.append('customerData', JSON.stringify({
      name: formData.name,
      email: formData.email,
      petName: formData.petName,
      specialRequests: formData.specialRequests,
    }));

    try {
      const response = await fetch('/api/submit-order', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        setMessage("Thank you for your order! We'll create your custom pet portrait within 2 business days.");
        // Reset form
        setFile(null);
        setStyle("");
        setFormData({
          name: "",
          email: "",
          petName: "",
          specialRequests: ""
        });
      } else {
        throw new Error('Failed to submit order');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage("Failed to submit order. Please try again or contact support.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Title>Custom Pet Portrait Order</Title>
      <Subtitle>Transform your beloved pet into a magical work of art!</Subtitle>

      <OrderForm onSubmit={handleSubmit}>
        <Section>
          <SectionTitle>1. Your Information</SectionTitle>
          <FormGrid>
            <FormField>
              <Label>Your Name*</Label>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                placeholder="Enter your name"
              />
            </FormField>
            <FormField>
              <Label>Your Email*</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                placeholder="Enter your email"
              />
            </FormField>
            <FormField>
              <Label>Pet's Name</Label>
              <Input
                type="text"
                name="petName"
                value={formData.petName}
                onChange={handleInputChange}
                placeholder="Enter your pet's name"
              />
            </FormField>
          </FormGrid>
        </Section>

        <Section>
          <SectionTitle>2. Upload Your Pet's Photo</SectionTitle>
          <UploadInfo>
            <li>Clear, front-facing photo works best</li>
            <li>Make sure your pet's face is visible</li>
            <li>Photo size should be under 10MB</li>
          </UploadInfo>
          <FileInput
            type="file"
            accept="image/*"
            onChange={(e) => {
              const selectedFile = e.target.files[0];
              if (selectedFile && selectedFile.size > 10 * 1024 * 1024) {
                setMessage("File size too large. Please upload an image under 10MB.");
                return;
              }
              setFile(selectedFile);
              setMessage("");
            }}
            required
          />
          {file && <FileName>Selected: {file.name}</FileName>}
        </Section>

        <Section>
          <SectionTitle>3. Choose Your Style</SectionTitle>
          <StyleGrid>
            {styleOptions.map(option => (
              <StyleCard
                key={option.id}
                isSelected={style === option.id}
                onClick={() => setStyle(option.id)}
              >
                <StyleName>{option.name}</StyleName>
                <StyleDesc>{option.description}</StyleDesc>
                <StyleButton isSelected={style === option.id}>
                  {option.buttonText}
                </StyleButton>
                <StylePrice>{option.price}</StylePrice>
              </StyleCard>
            ))}
          </StyleGrid>
        </Section>

        <Section>
          <SectionTitle>4. Special Requests (Optional)</SectionTitle>
          <TextArea
            name="specialRequests"
            value={formData.specialRequests}
            onChange={handleInputChange}
            placeholder="Any special requests or preferences for your portrait?"
            rows="4"
          />
        </Section>

        <OrderButton type="submit" disabled={loading}>
          {loading ? "Submitting Order..." : "Place Order - $49.99"}
        </OrderButton>

        {message && (
          <MessageBox error={message.toLowerCase().includes('failed')}>
            {message}
          </MessageBox>
        )}
      </OrderForm>

      <OrderInfo>
        <h3>What happens next?</h3>
        <ol>
          <li>We'll receive your order and review your photo</li>
          <li>Your custom portrait will be created within 2 business days</li>
          <li>We'll send your magical pet portrait to your email</li>
          <li>You can request revisions if needed</li>
        </ol>
      </OrderInfo>
    </Container>
  );
};

export default Order;