import React, { useState } from "react";
import styled from "styled-components";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is PetArtify?",
      answer:
        "PetArtify is an AI-powered service that transforms your pet's photos into beautiful, custom artwork in various styles.",
    },
    {
      question: "How does the ordering process work?",
      answer:
        "Simply upload a photo of your pet, select your preferred art style, and place your order. We’ll process your request and deliver your custom artwork.",
    },
    {
      question: "What art styles do you offer?",
      answer:
        "We currently offer watercolor, oil painting, sketch, and pixel art styles. We are constantly adding more styles to choose from.",
    },
    {
      question: "How long does it take to receive my artwork?",
      answer:
        "Digital artwork is typically delivered within 2-3 business days. For physical prints, shipping times may vary depending on your location.",
    },
    {
      question: "Can I order multiple portraits?",
      answer:
        "Yes! You can upload multiple photos and place separate orders for each. We also offer discounts for bulk orders.",
    },
    {
      question: "What file format will I receive?",
      answer:
        "Your digital artwork will be delivered in high-resolution JPEG format, suitable for printing and sharing.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Container>
      <h1>Frequently Asked Questions</h1>
      <FAQList>
        {faqs.map((faq, index) => (
          <FAQItem key={index}>
            <Question onClick={() => toggleFAQ(index)}>
              {faq.question}
              <span>{activeIndex === index ? "-" : "+"}</span>
            </Question>
            {activeIndex === index && <Answer>{faq.answer}</Answer>}
          </FAQItem>
        ))}
      </FAQList>
    </Container>
  );
};

export default FAQ;

/* Styled Components */
const Container = styled.div`
  text-align: center;
  padding: 2em;
  font-family: Arial, sans-serif;

  h1 {
    color: #333;
    margin-bottom: 1em;
  }
`;

const FAQList = styled.div`
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
`;

const FAQItem = styled.div`
  margin: 1em 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 1em;
`;

const Question = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  cursor: pointer;

  span {
    font-size: 1.5em;
    color: #77c0d9;
  }

  &:hover {
    color: #77c0d9;
  }
`;

const Answer = styled.div`
  margin-top: 0.5em;
  font-size: 1em;
  color: #555;
  line-height: 1.5;
`;

