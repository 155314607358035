import React, { useState } from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import logo from "../assets/logo.jpeg"; // Replace with your logo image.

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <HeaderContainer>
      <Logo>
        <Link to="/">
          <img src={logo} alt="PetArtify Logo" />
        </Link>
      </Logo>
      <Hamburger onClick={toggleMenu}>
        <span />
        <span />
        <span />
      </Hamburger>
      <Nav menuOpen={menuOpen}>
        <NavItem>
          <NavLink to="/" exact activeClassName="active" onClick={toggleMenu}>
            Home
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/order" activeClassName="active" onClick={toggleMenu}>
            Order
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/gallery" activeClassName="active" onClick={toggleMenu}>
            Gallery
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/faq" activeClassName="active" onClick={toggleMenu}>
            FAQ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/contact" activeClassName="active" onClick={toggleMenu}>
            Contact
          </NavLink>
        </NavItem>
        <OrderButton>
          <Link to="/order" onClick={toggleMenu}>Order Now</Link>
        </OrderButton>
      </Nav>
    </HeaderContainer>
  );
};

export default Header;

/* Styled Components */
const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  background-color: #77c0d9;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.div`
  img {
    height: 50px;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

const Hamburger = styled.div`
  display: none;
  flex-direction: column;
  cursor: pointer;
  span {
    height: 3px;
    width: 25px;
    background: white;
    margin: 3px 0;
    transition: all 0.3s ease-in-out;
  }

  @media (max-width: 768px) {
    display: flex;
  }
`;

const Nav = styled.nav`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: ${(props) => (props.menuOpen ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #77c0d9;
    width: 100%;
    padding: 1em 0;
    z-index: 999;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
`;

const NavItem = styled.div`
  margin: 0 1em;

  a {
    color: white;
    text-decoration: none;
    font-size: 1em;
    position: relative;

    &:hover {
      color: #ff6b6b;
    }

    &.active {
      font-weight: bold;
      border-bottom: 2px solid white;
    }
  }

  @media (max-width: 768px) {
    margin: 1em 0;
  }
`;

const OrderButton = styled.div`
  margin-left: 1em;

  a {
    background-color: white;
    color: #77c0d9;
    padding: 0.8em 1.5em;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #ff6b6b;
      color: white;
    }
  }

  @media (max-width: 768px) {
    margin-top: 1em;
    width: 100%;
    text-align: center;

    a {
      display: block;
    }
  }
`;
