import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import petImage from "../assets/logo.jpeg"; // Replace with your own image.

const Home = () => (
  <Container>
    <Hero>
      <TextSection>
        <motion.h1
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1 }}
        >
          AI-Generated Custom Pet Portraits
        </motion.h1>
        <motion.p
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5 }}
        >
          Transform your pet's photos into stunning artwork with the power of AI.
        </motion.p>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        >
          <Link to="/order">
            <Button>Get Started</Button>
          </Link>
        </motion.div>
      </TextSection>
      <ImageSection>
        <motion.img
          src={petImage}
          alt="Pet Portrait"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1.5 }}
        />
      </ImageSection>
    </Hero>
  </Container>
);

export default Home;

/* Styled Components */
const Container = styled.div`
  text-align: center;
  padding: 2em;
  font-family: Arial, sans-serif;
`;

const Hero = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #eaf8fc;
  padding: 2em;
  border-radius: 10px;
  margin-top: 2em;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 1em;
  }
`;

const TextSection = styled.div`
  flex: 1;
  margin-right: 2em;

  h1 {
    font-size: 2.5em;
    color: #333;

    @media (max-width: 768px) {
      font-size: 1.8em;
    }
  }

  p {
    margin: 1em 0;
    font-size: 1.2em;
    color: #555;

    @media (max-width: 768px) {
      font-size: 1em;
    }
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const ImageSection = styled.div`
  flex: 1;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
      margin-top: 1em;
    }
  }
`;

const Button = styled.button`
  margin-top: 1em;
  padding: 1em 2em;
  background-color: #77c0d9;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #5aa9bc;
  }

  @media (max-width: 768px) {
    font-size: 1em;
    padding: 0.8em 1.5em;
  }
`;
